import { Injectable, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalContent } from './modal.content';

export interface ModalAnimation {
  from: string;
  to: string;
  additionalStyle?: string;
}

export interface Modal<T extends ModalContent = any> {
  component: Type<T>;
  zIndex: number;
  padding?: number;
  data?: Partial<Record<keyof T, T[keyof T]>>;
  cardStyle?: Record<string, string>;
  fromElement?: () => HTMLDivElement | undefined;
  fromRect?: DOMRect;
  initialOpacity?: number;
  overlay?: string;
  durationMs?: number;
  animations?: ModalAnimation[];

  width?: string | number;
  onClose?: () => void;
  onCalculatedStatus?: (isCalculated: boolean) => void;
}

export interface ModalWithUid<T extends ModalContent> extends Modal<T> {
  uid: number;
}

@Injectable({ providedIn: 'root' })
export class ModalService {

  constructor() {}

  readonly newModalSubject = new Subject<Modal>();

  show<T extends ModalContent>(modal: Modal<T>): void {
    this.newModalSubject.next(modal);
  }

}
